<template>
  <v-row>
    <v-col :cols="3">
      <v-list elevation="2" class="ma-2" style="height:600px;overflow:auto">
        <v-list-item-group v-model="selectedId">
          <v-subheader>Instructors</v-subheader>
          <v-list-item v-for="{ _id, name } in instructors" :key="_id" :value="_id">
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-subheader>Accompanists</v-subheader>
          <v-list-item v-for="{ _id, name } in accompanists" :key="_id" :value="_id">
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-dialog v-model="addDialog" width="400">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="success" class="ml-2 mb-2 mr-2" style="width:calc(100% - 14px)">
            <v-icon left>fal fa-plus</v-icon>
            Add Person
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add Instructor or Accompanist</v-card-title>
          <v-card-text>
            <v-select v-model="typeToAdd" label="Person Type" :items="['Instructor', 'Accompanist']" outlined />
            <v-text-field v-model="nameToAdd" label="Name" outlined />
            <v-btn small @click="addTimes = addTimeOptions.map(({ value }) => value)">Check All</v-btn>
            <v-list dense>
              <v-list-item v-for="{ value, text } in addTimeOptions" :key="value" class="pl-0 pr-0" @click="toggleTime(value)">
                <v-list-item-action class="mr-1">
                  <v-icon small>{{ addTimes.includes(value) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="addDialog = false">Cancel</v-btn>
            <v-btn :disabled="nameToAdd === '' || addTimes.length === 0 || addingName" color="success" text @click="addName()">{{ addingName ? 'Adding...' : 'Add Person' }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col :cols="9">
      <v-card v-if="selected" class="ma-2 pt-4">
        <v-card-title>Availability for {{ selected.name }}</v-card-title>
        <v-card-text class="pt-4">
          <v-row v-for="{ day, date, id, startTime, endTime, isAm } in selectedAvailability" :key="day">
            <v-col :cols="4" class="pt-0 pb-0 pr-1">
              <v-text-field :value="day" solo dense readonly />
            </v-col>
            <v-col :cols="3" class="pt-0 pb-0 pl-1 pr-1">
              <v-select :value="startTime" :items="isAm ? amStart : pmStart" solo dense hide-details @change="(val) => updateAvail({ id, date, isAm, start: val })" />
            </v-col>
            <v-col :cols="3" class="pt-0 pb-0 pl-1 pr-1">
              <v-select :value="endTime" :items="isAm ? amEnd : pmEnd" solo dense hide-details @change="(val) => updateAvail({ id, date, isAm, end: val })" />
            </v-col>
            <v-col :cols="2" class="pt-0 pb-0 pl-1">
              <v-btn v-if="id" color="error" style="margin-top:1px" @click="removeAvailability(id)">
                <v-icon left>fas fa-times-circle</v-icon>
                Clear
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    days: {
      type: Array,
      required: true
    }
  },
  setup (props, { root }) {
    const setupService = root.$feathers.service('student/music/jury-setup')

    const addTimeOptions = computed(() => {
      const arr = []
      for (const day of props.days) {
        const dt = new Date(day)
        arr.push({ value: day.substring(0, 10) + '-AM', text: stringFormatDate(dt, true, true) + ' - Morning' })
        arr.push({ value: day.substring(0, 10) + '-PM', text: stringFormatDate(dt, true, true) + ' - Afternoon' })
      }
      return arr
    })

    const instructors = ref([])
    const accompanists = ref([])
    const selectedId = ref(null)
    const selected = computed(() => {
      if (selectedId.value == null) return null
      const rec = instructors.value.find(({ _id }) => _id === selectedId.value)
      if (rec) return { ...rec, type: 'Instructor' }
      const rec2 = accompanists.value.find(({ _id }) => _id === selectedId.value)
      if (rec2) return { ...rec2, type: 'Accompanist' }
      return null
    })
    const selectedAvailability = computed(() => {
      // Merge the days and the selected availability; we always want all of the days' AM/PM slots to be visible
      const arr = []
      for (const dt of props.days) {
        const date = dt.substring(0, 10)
        const day = stringFormatDate(dt).substring(0, 17)
        const am = selected.value.availability.find(({ start }) => start.substring(0, 10) === date && new Date(start).getHours() < 12)
        const pm = selected.value.availability.find(({ start }) => start.substring(0, 10) === date && new Date(start).getHours() >= 12)
        if (am) {
          const st = new Date(am.start).toLocaleTimeString().split(':')
          const en = new Date(am.end).toLocaleTimeString().split(':')
          arr.push({ day: day + ' AM', date, id: am._id, startTime: st[0] + ':' + st[1] + ' AM', endTime: en[0] + ':' + en[1] + en[2].substring(2), isAm: true })
        } else {
          arr.push({ day: day + ' AM', date, isAm: true })
        }
        if (pm) {
          const st = new Date(pm.start).toLocaleTimeString().split(':')
          const en = new Date(pm.end).toLocaleTimeString().split(':')
          arr.push({ day: day + ' PM', date, id: pm._id, startTime: st[0] + ':' + st[1] + ' PM', endTime: en[0] + ':' + en[1] + en[2].substring(2), isAm: false })
        } else {
          arr.push({ day: day + ' PM', date, isAm: false })
        }
      }
      return arr
    })
    const addDialog = ref(false)
    const typeToAdd = ref('Instructor')
    const nameToAdd = ref('')
    const addTimes = ref([])
    const addingName = ref(false)
    async function addName () {
      addingName.value = true
      const name = nameToAdd.value
      const availability = addTimes.value.map((val) => {
        const day = val.substring(0, 10)
        const startTime = val.substring(11) === 'AM' ? '10:00 AM' : '1:00 PM'
        const endTime = val.substring(11) === 'AM' ? '12:00 PM' : '3:00 PM'
        const start = new Date(day + ' ' + startTime)
        const end = new Date(day + ' ' + endTime)
        return { start, end }
      })
      const patch = { $addToSet: {} }
      if (typeToAdd.value === 'Instructor') patch.$addToSet = { instructors: { name, availability } }
      else patch.$addToSet = { accompanists: { name, availability } }
      const rec = await setupService.patch(props.id, patch)
      instructors.value = rec.instructors
      accompanists.value = rec.accompanists
      addDialog.value = false
      addingName.value = false
    }

    function toggleTime (val) {
      const index = addTimes.value.findIndex((v) => v === val)
      console.log(index)
      if (index >= 0) addTimes.value.splice(index, 1)
      else addTimes.value.push(val)
    }

    const amStart = ref(['10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM'])
    const amEnd = ref(['10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM'])
    const pmStart = ref(['1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM'])
    const pmEnd = ref(['1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM'])

    async function updateAvail ({ id: availId, date, isAm, start, end }) {
      const str = selected.value.type.toLowerCase() + 's.$[main].availability'
      if (availId) {
        const patch = {}
        if (start) {
          const dt = new Date(date + ' ' + start)
          patch[str + '.$[avail].start'] = dt
        }
        if (end) {
          const dt = new Date(date + ' ' + end)
          patch[str + '.$[avail].end'] = dt
        }
        const rec = await setupService.patch(props.id, patch, { query: { arrayFilters: [{ 'main._id': selected.value._id }, { 'avail._id': availId }] } })
        instructors.value = rec.instructors
        accompanists.value = rec.accompanists
      } else {
        const patch = { }
        if (start) {
          const dt = new Date(date + ' ' + start)
          patch.start = dt
          const end = new Date(date + ' ' + (isAm ? '12:00 PM' : '3:00 PM'))
          patch.end = end
        }
        if (end) {
          const dt = new Date(date + ' ' + end)
          patch.end = dt
          const start = new Date(date + ' ' + (isAm ? '10:00 AM' : '12:00 PM'))
          patch.start = start
        }
        const rec = await setupService.patch(props.id, { $push: { [str]: patch } }, { query: { arrayFilters: [{ 'main._id': selected.value._id }] } })
        instructors.value = rec.instructors
        accompanists.value = rec.accompanists
      }
    }

    async function removeAvailability (_id) {
      if (selected.value.type === 'Instructor') {
        for (let i = 0; i < instructors.value.length; i++) {
          const index = instructors.value[i].availability.findIndex(({ _id: i }) => i === _id)
          if (index >= 0) {
            const patch = {}
            const temp = JSON.parse(JSON.stringify(instructors.value[i].availability))
            temp.splice(index, 1)
            patch['instructors.' + i + '.availability'] = temp
            const rec = await setupService.patch(props.id, patch)
            instructors.value = rec.instructors
            break
          }
        }
      } else if (selected.value.type === 'Accompanist') {
        for (let i = 0; i < accompanists.value.length; i++) {
          const index = accompanists.value[i].availability.findIndex(({ _id: i }) => i === _id)
          if (index >= 0) {
            const patch = {}
            const temp = JSON.parse(JSON.stringify(accompanists.value[i].availability))
            temp.splice(index, 1)
            patch['accompanists.' + i + '.availability'] = temp
            const rec = await setupService.patch(props.id, patch)
            accompanists.value = rec.accompanists
            break
          }
        }
      }
    }

    watch(() => props.id, async () => {
      if (props.id == null || props.id === '') return
      const rec = await setupService.get(props.id)
      instructors.value = rec.instructors
      accompanists.value = rec.accompanists
    })

    return {
      addTimeOptions,
      instructors,
      accompanists,
      selectedId,
      selected,
      selectedAvailability,
      addDialog,
      typeToAdd,
      nameToAdd,
      addTimes,
      addingName,
      addName,
      toggleTime,
      amStart,
      amEnd,
      pmStart,
      pmEnd,
      updateAvail,
      removeAvailability
    }
  }
}
</script>
