var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('v-list',{staticClass:"ma-2",staticStyle:{"height":"600px","overflow":"auto"},attrs:{"elevation":"2"}},[_c('v-list-item-group',{model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}},[_c('v-subheader',[_vm._v("Instructors")]),_vm._l((_vm.instructors),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('v-list-item',{key:_id,attrs:{"value":_id}},[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1)}),_c('v-divider'),_c('v-subheader',[_vm._v("Accompanists")]),_vm._l((_vm.accompanists),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('v-list-item',{key:_id,attrs:{"value":_id}},[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1)})],2)],1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mb-2 mr-2",staticStyle:{"width":"calc(100% - 14px)"},attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add Person ")],1)]}}]),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Instructor or Accompanist")]),_c('v-card-text',[_c('v-select',{attrs:{"label":"Person Type","items":['Instructor', 'Accompanist'],"outlined":""},model:{value:(_vm.typeToAdd),callback:function ($$v) {_vm.typeToAdd=$$v},expression:"typeToAdd"}}),_c('v-text-field',{attrs:{"label":"Name","outlined":""},model:{value:(_vm.nameToAdd),callback:function ($$v) {_vm.nameToAdd=$$v},expression:"nameToAdd"}}),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.addTimes = _vm.addTimeOptions.map(function (ref) {
	var value = ref.value;

	return value;
})}}},[_vm._v("Check All")]),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.addTimeOptions),function(ref){
var value = ref.value;
var text = ref.text;
return _c('v-list-item',{key:value,staticClass:"pl-0 pr-0",on:{"click":function($event){return _vm.toggleTime(value)}}},[_c('v-list-item-action',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.addTimes.includes(value) ? 'fas fa-check-square' : 'fal fa-square'))])],1),_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.nameToAdd === '' || _vm.addTimes.length === 0 || _vm.addingName,"color":"success","text":""},on:{"click":function($event){return _vm.addName()}}},[_vm._v(_vm._s(_vm.addingName ? 'Adding...' : 'Add Person'))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":9}},[(_vm.selected)?_c('v-card',{staticClass:"ma-2 pt-4"},[_c('v-card-title',[_vm._v("Availability for "+_vm._s(_vm.selected.name))]),_c('v-card-text',{staticClass:"pt-4"},_vm._l((_vm.selectedAvailability),function(ref){
var day = ref.day;
var date = ref.date;
var id = ref.id;
var startTime = ref.startTime;
var endTime = ref.endTime;
var isAm = ref.isAm;
return _c('v-row',{key:day},[_c('v-col',{staticClass:"pt-0 pb-0 pr-1",attrs:{"cols":4}},[_c('v-text-field',{attrs:{"value":day,"solo":"","dense":"","readonly":""}})],1),_c('v-col',{staticClass:"pt-0 pb-0 pl-1 pr-1",attrs:{"cols":3}},[_c('v-select',{attrs:{"value":startTime,"items":isAm ? _vm.amStart : _vm.pmStart,"solo":"","dense":"","hide-details":""},on:{"change":function (val) { return _vm.updateAvail({ id: id, date: date, isAm: isAm, start: val }); }}})],1),_c('v-col',{staticClass:"pt-0 pb-0 pl-1 pr-1",attrs:{"cols":3}},[_c('v-select',{attrs:{"value":endTime,"items":isAm ? _vm.amEnd : _vm.pmEnd,"solo":"","dense":"","hide-details":""},on:{"change":function (val) { return _vm.updateAvail({ id: id, date: date, isAm: isAm, end: val }); }}})],1),_c('v-col',{staticClass:"pt-0 pb-0 pl-1",attrs:{"cols":2}},[(id)?_c('v-btn',{staticStyle:{"margin-top":"1px"},attrs:{"color":"error"},on:{"click":function($event){return _vm.removeAvailability(id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-times-circle")]),_vm._v(" Clear ")],1):_vm._e()],1)],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }